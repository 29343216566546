import React from 'react';
import styles from './WhyUs.module.css';

const WhyUs = () => {
	return (
		<>
			<div className={styles.divider}></div>
			<div
				id='about'
				className={styles.container}
			>
				<div className={styles.heading}>
					<h3>Why Le Prestige?</h3>
				</div>
				<div className={styles['two-columns']}>
					<p>
						We provide end-to-end services consisting of Innovative
						Business Idea generation, Documentation, Interview
						Preparation, Business and Financial Planning, Appeals,
						and much more.
					</p>
					<p>
						Our services are not just limited to assisting clients
						in obtaining their visas; we remain with them until they
						obtain their citizenship and have fully settled down.
					</p>
				</div>
			</div>
		</>
	);
};

export default WhyUs;
