import React from 'react';
import styles from './Service.module.css';

const services = [
	{
		title: 'Bigger Opportunities',
		text: `With its diverse job sectors, admirable working conditions, and numerous employment opportunities, the United Kingdom is one of the most appealing places globally for job seekers.`,
		icon: '/image/pound-currency.png',
		alt: 'Pound currency icon',
	},
	{
		title: 'Free healthcare for all',
		text: `Anyone legally residing in the United Kingdom is entitled to free NHS healthcare. Moreover, you are not required to be a British Citizen or even be employed to benefit from free Healthcare.`,
		icon: '/image/plus.png',
		alt: 'Medical aid icon',
	},
	{
		title: 'Safety & Freedom',
		text: `The United Kingdom is one of the least corrupt nations in the
    world. According to an OECD survey, 78 percent of UK residents
    feel safe walking alone at night.`,
		icon: 'image/shield.png',
		alt: 'shield icon',
	},
	{
		title: 'World-class education',
		text: `The United Kingdom is reputed for its high quality of education, interactive teaching methods, and Top ranking universities. Universities in the UK consistently rank high in the global university rankings and degrees earned from the country are globally recognised and preferred by employers all over the world.`,
		icon: 'image/graduation-hat.png',
		alt: 'graduation hat icon',
	},
];

const Service = () => {
	return (
		<div
			className={styles.service}
			id='benefits'
		>
			<div className={styles['service-content']}>
				<p className={styles['title-over']}>
					Benefits of living in the UK
				</p>
				<div className={styles['service-left']}>
					<h2>
						For those who are seeking a{' '}
						<span className={styles.underline}>better life</span>
					</h2>
				</div>
				<div className={styles['service-right']}>
					{services.map((service, i) => {
						return (
							<div
								key={i}
								className={styles['service-item']}
							>
								<div>
									<div className={styles.icon}>
										<img
											src={service.icon}
											alt={service.alt}
										/>
									</div>
								</div>
								<p
									className={`${styles['subtitle-weight']} subtitle`}
								>
									{service.title}
								</p>
								<p>{service.text}</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Service;
