import React from 'react';
import styles from './Form.module.css';

const Form = ({ children, bgImage, heading }) => {
	return (
		<div className={styles.container}>
			<header
				className={styles.header}
				style={{ backgroundImage: `url(${bgImage})` }}
			>
				<h3 className={styles.heading}>{heading}</h3>
				<div className={styles.logo}>
					<img
						src='/image/logo-white.png'
						alt='logo'
					/>
				</div>
			</header>
			<div className={styles['form-container']}>{children}</div>;
		</div>
	);
};

export default Form;
