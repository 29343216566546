import React, { useState } from 'react';
import styles from './Accordion.module.css';

const Accordion = ({ heading, content }) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<>
			<div
				className={styles.accordion}
				onClick={() => setOpen((prev) => !prev)}
			>
				<div className={styles.heading}>
					<div
						className={
							isOpen ? styles['show-less'] : styles['show-more']
						}
					></div>
					<h4>{heading}</h4>
				</div>
				<p className={`${styles.content} ${isOpen && styles.open}`}>
					{content}
				</p>
			</div>
			<div className={styles.divider}></div>
		</>
	);
};

export default Accordion;
