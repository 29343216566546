import React, { createElement, useEffect, useState } from 'react';
import Home from './pages/home/Home';
import Footer from './component/layout/Footer/Footer';
import InfoForm from './component/forms/InfoForm/InfoForm';
import ConsultationForm from './component/forms/ConsultationForm/ConsultationForm';
import Header from './component/layout/Header/Header';

function App() {
	const [isConsultationFormOpen, setIsConsultationFormOpen] = useState(false);
	const [isInfoFormOpen, setIsInfoFormOpen] = useState(false);

	const handleConsultationFormOpen = () => {
		setIsConsultationFormOpen(true);
		window.location.hash = '#assessment';
	};

	const handleInfoFormOpen = () => {
		setIsInfoFormOpen(true);
		window.location.hash = '#info';
	};

	const handleConsultationFormClose = () => {
		setIsConsultationFormOpen(false);
		window.location.hash = '';
	};

	const handleInfoFormClose = () => {
		setIsInfoFormOpen(false);
		window.location.hash = '';
	};

	useEffect(() => {
		if (window.location.hash === '#assessment') {
			setIsConsultationFormOpen(true);
		}

		if (window.location.hash === '#info') {
			setIsInfoFormOpen(true);
		}

		const sections = ['benefits', 'service', 'testimonials', 'about'];

		sections.forEach((section) => {
			if (window.location.hash === `#${section}`) {
				const a = document.createElement('a');
				a.href = `#${section}`;
				a.click();
			}
		});
	}, [window.location.hash]);

	return (
		<div className='App'>
			<Header />
			<main className='padding-top'>
				<Home
					handleConsultationFormOpen={handleConsultationFormOpen}
					handleInfoFormOpen={handleInfoFormOpen}
				/>
			</main>
			<Footer
				handleConsultationFormOpen={handleConsultationFormOpen}
				handleInfoFormOpen={handleInfoFormOpen}
			/>
			<ConsultationForm
				open={isConsultationFormOpen}
				handleClose={handleConsultationFormClose}
			/>
			<InfoForm
				open={isInfoFormOpen}
				handleClose={handleInfoFormClose}
			/>
		</div>
	);
}

export default App;
