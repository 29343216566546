import React, { useEffect } from 'react';
import styles from './Modal.module.css';
import { MdClose } from 'react-icons/md';
import RoundButton from '../RoundButton/RoundButton';

const Modal = ({
	open,
	handleClose,
	children,
	plain,
	clickAwayDisabled = false,
	closeButtonRight,
}) => {
	const handleCloseModal = ({ target }) => {
		if (target.dataset.target === 'backdrop') {
			if (clickAwayDisabled) return;
			handleClose();
		}
	};

	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden';
		}
		return () => (document.body.style.overflow = 'unset');
	}, [open]);

	if (open) {
		return (
			<div
				data-target='backdrop'
				onClick={handleCloseModal}
				className={styles.backdrop}
				style={{ backgroundColor: plain && '#161616' }}
			>
				<div
					className={styles['close-button']}
					onClick={handleClose}
					style={closeButtonRight && { left: 20, right: 'unset' }}
				>
					<RoundButton>
						<MdClose
							style={{
								color: 'lightgray',
								fontSize: 36,
								display: 'block',
							}}
						/>
					</RoundButton>
				</div>

				{children}
			</div>
		);
	}
};

export default Modal;
