import React from 'react';
import styles from './Review.module.css';

import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const reviewData = [
	{
		quote: `I would like to recommend their services to anyone looking to immigrate to the UK`,
		name: 'Ameer Hamza',
		position: 'Compliance Manager',
		image: '/image/Ameer Hamza.png',
		video: 'VgBD-Q8ffsE',
	},
	{
		quote: `Throughout the whole process, they dealt with me very professionally, and got my visa approved before the promised date`,
		name: 'Rohit Mehta',
		position: 'Private Business',
		image: '/image/Rohit Mehta.png',
		video: 'KmxvGsvBrKo',
	},
];

const Review = () => {
	return (
		<div
			className={styles.review}
			id='testimonials'
		>
			<Swiper
				modules={[Navigation, Pagination, A11y]}
				spaceBetween={20}
				slidesPerView={1}
				navigation
				pagination={{ clickable: true }}
				updateOnWindowResize={true}
			>
				{reviewData.map((review, i) => {
					return (
						<SwiperSlide key={i}>
							<div className={styles['review-content']}>
								<div className={styles['review-left']}>
									<div className={styles['review-container']}>
										<p className={styles.quote}>
											<span
												className={
													styles['squote-mark-open']
												}
											>
												{' '}
												“
											</span>
											{review.quote}
											<span
												className={
													styles['squote-mark-close']
												}
											>
												”
											</span>
										</p>
										<div className={styles['person-data']}>
											<div className={styles.avatar}>
												<img
													src={review.image}
													alt='user'
												/>
											</div>
											<div>
												<p className='base1'>
													{review.name}
												</p>
												<p className='base2'>
													{review.position}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className={styles['review-right']}>
									<div className={styles.slide}>
										<iframe
											className={styles.iframe}
											frameBorder={0}
											src={`https://www.youtube.com/embed/${review.video}`}
										></iframe>
									</div>
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Review;
