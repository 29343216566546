import React from 'react';
import BlackButton from '../../general/BlackButton/BlackButton';
import MainButton from '../../general/MainButton/MainButton';
// import './Footer.css';
import styles from './Footer.module.css';

const Footer = ({ handleConsultationFormOpen, handleInfoFormOpen }) => {
	return (
		<>
			<footer className={styles.footer}>
				<div className={styles.logo}>
					<a href='/'>
						<img
							src='/image/logo.png'
							alt='logo'
						/>
					</a>
				</div>
				<div className={styles['footer-content']}>
					<h2 className='footer-heading'>
						Business Immigration allows applicants from all over the
						world to either start a new business or expand an
						existing one.
					</h2>
				</div>
				<div className={styles.buttons}>
					<MainButton
						text='Book a Free Assessment'
						onClick={handleConsultationFormOpen}
					/>
					<div className={styles['secondary-button']}>
						<BlackButton
							text='Request more information'
							onClick={handleInfoFormOpen}
						/>
					</div>
					<a
						className={styles['secondary-link']}
						href='#info'
						onClick={(e) => {
							e.preventDefault();
							handleInfoFormOpen();
						}}
					>
						Request more information
					</a>
				</div>
			</footer>
			<div className={styles.copyright}>
				<p>Copyright © 2022 Le Prestige</p>
			</div>
		</>
	);
};

export default Footer;
