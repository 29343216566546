import React from 'react';
import MainButton from '../../general/MainButton/MainButton';
import styles from './Banner.module.css';

import { CgSandClock } from 'react-icons/cg';
import { FaPassport } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const achievementsInfo = [
	{
		text: '11 Years of Experience',
		icon: <CgSandClock />,
	},
	{ text: '680 Satisfied Clients', icon: <FaUserAlt /> },
	{ text: '560 Citizenships granted', icon: <FaPassport /> },
];

const Banner = ({ handleConsultationFormOpen, handleInfoFormOpen }) => {
	return (
		<div className={styles.banner}>
			<div className={styles['banner-content']}>
				<h1>Do you want a UK Passport?</h1>
				<p className={`subtitle ${styles.subtitle}`}>
					Show 200 000 AED in your bank account for 30 days <br />
					and get UK Passport.
				</p>
				<div className={styles['primary-button']}>
					<MainButton
						text='Book a Free Assessment'
						onClick={handleConsultationFormOpen}
					/>
					<a
						href='#assessment'
						onClick={(e) => {
							e.preventDefault();
							handleInfoFormOpen();
						}}
					>
						Request more information
					</a>
				</div>
				<div className={styles.achievements}>
					<IconContext.Provider
						value={{
							style: {
								fontSize: 20,
								display: 'block',
							},
						}}
					>
						{achievementsInfo.map((achievement, i) => {
							return (
								<React.Fragment key={i}>
									{i > 0 && (
										<p
											className={`${styles.divider} ${styles['achievement-text']}`}
										>
											|
										</p>
									)}
									<div
										key={i}
										className={styles.achievement}
									>
										<div>{achievement.icon}</div>
										<p
											className={
												styles['achievement-text']
											}
										>
											{achievement.text}
										</p>
									</div>
								</React.Fragment>
							);
						})}
					</IconContext.Provider>
				</div>
			</div>
			<div
				className={styles['banner-img']}
				style={{ backgroundImage: `url("/image/united-kingdom.png")` }}
			></div>
		</div>
	);
};

export default Banner;
