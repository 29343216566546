import React, { useState } from 'react';
import styles from './InfoForm.module.css';
import BlackButton from '../../general/BlackButton/BlackButton';
import Modal from '../../general/Modal/Modal';
import Form from '../../general/Form/Form';
import TextInput from '../../general/TextInput/TextInput';

const formInputs = [
	{
		id: 'name',
		name: 'name',
		placeholder: 'David',
		label: 'Name',
		required: true,
		type: 'text',
	},
	{
		id: 'phone',
		name: 'phone',
		placeholder: '+971 XXX XXX XXX',
		label: 'Phone',
		required: true,
		type: 'tel',
	},
	{
		id: 'email',
		name: 'email',
		placeholder: 'david.mahit@gmail.com',
		label: 'Email',
		required: true,
		type: 'email',
	},
];

const InfoForm = ({ open, handleClose }) => {
	const [fetchStatus, setFetchStatus] = useState('');
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setIsButtonLoading(true);

		const formData = {
			_subject: `${e.target.name.value} submitted a request for additional information`,
			Name: e.target.name.value,
			Phone: e.target.phone.value,
			Email: e.target.email.value,
		};

		fetch('https://formspree.io/f/xlevwkgz', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (response.ok) {
					setFetchStatus(
						'Thank you for taking the time to contact us. We will be in touch shortly.'
					);
				} else {
					response.json().then((data) => {
						if (Object.hasOwn(data, 'errors')) {
							setFetchStatus(
								data['errors']
									.map((error) => error['message'])
									.join(', ')
							);
						} else {
							setFetchStatus(
								'Something went wrong. Please try again.'
							);
						}
					});
				}
			})
			.catch((error) => {
				console.log(error);
				setFetchStatus('Something went wrong. Please try again.');
			});
	};

	const form = (
		<form
			onSubmit={handleSubmit}
			className={styles['form-container']}
		>
			<div className={styles['inputs-container']}>
				{formInputs.map((input) => {
					return (
						<div
							key={input.id}
							className={styles['input-item']}
						>
							<TextInput
								id={input.id}
								name={input.name}
								placeholder={input.placeholder}
								label={input.label}
								type={input.type}
								required={input.required}
							/>
						</div>
					);
				})}
			</div>
			<BlackButton
				text='Request more information'
				type='submit'
				loading={isButtonLoading}
			/>
		</form>
	);

	return (
		<Modal
			open={open}
			handleClose={handleClose}
		>
			<Form
				bgImage='/image/bg-info.jpg'
				heading='Enter your details to receive a detailed guide of our process'
			>
				{fetchStatus || form}
			</Form>
		</Modal>
	);
};

export default InfoForm;
