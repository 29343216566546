import React from 'react';
import Accordion from '../../general/Accordion/Accordion';
// import './Expertise.css';
import styles from './Expertise.module.css';

const accordionContent = [
	{
		heading: 'Innovator visa',
		content:
			'This visa category is for applicants with prior Business Experience and allows them to open multiple businesses in the UK with the added benefit of Free Healthcare and Education for their dependants. This route directly leads to British Residency and Citizenship in just 3 Years.',
	},
	{
		heading: 'Expansion worker Visa',
		content:
			'This Route allows you to become a British Resident by just opening a branch of your Business in the UK.',
	},
];

const Expertise = () => {
	return (
		<div
			id='service'
			className={styles.expertise}
		>
			<div className={styles['expertise-left']}>
				<div className={styles['img-container']}>
					<img
						src='/image/united-kingdom-night.png'
						alt='united-kingdom-night'
					/>
					<div className={styles['text-over-img']}>
						<p>Le Prestige Immigration is an</p>
						<div>
							<p>
								International Immigration Law firm with Office
								in London, Toronto and Dubai
							</p>
							<span></span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles['expertise-right']}>
				<div>
					<p className={styles['title-over']}>Business Immigration</p>
					<h2>Le Prestige expertise</h2>
				</div>
				<div>
					{accordionContent.map((accordionItem, i) => {
						return (
							<Accordion
								key={i}
								heading={accordionItem.heading}
								content={accordionItem.content}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Expertise;
